import { Link } from "react-router-dom";
import SnowflakeSavings from "../../assets/images/svgs/platform/header.png";
import Header from "../../components/header";
import check from "../../dbt_assets/images/check.svg";
import { DBT_NAME } from "../../constant";

const platformDevPoints = [
  "Autotune warehouse configs 24/7",
  "Pinpoint issues in query code and recommend optimized query",
  "Prevent costly mistakes from data consumers in development",
  "Perform admin tasks autnomously",
];

export const PlatformHeader = () => (
  <div className="dbt-hero header">
    <div className="custom-container">
      <Header />
      <div className="md:pt-[120px] pt-[80px] md:pb-[60px] pb-0 flex md:flex-row flex-col justify-center items-center md:gap-[50px] gap-0">
        <div className="md:basis-1/2 flex flex-col md:justify-start md:items-start justify-center items-center gap-8">
          <h1 className="text-[#082247] md:text-[48px] text-[36px] font-[600] md:pt-0 pt-10">
            {/* <div className="text-[#082247] md:text-5xl text-4xl font-semibold font-['Lexend'] md:text-left text-center"> */}
            Automate Infra. Savings & Prevent Costly Mistakes with AI Teammates{" "}
            {/* </div> */}
          </h1>
          <Link to="https://app.myaltimate.com/contactus">
            <button className="px-5 py-3 bg-[#3f8cff] rounded-[10px] justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Lexend'] leading-relaxed">
              <div className="inline-flex gap-1">
                Message Us for the Recorded Demo
              </div>
            </button>
          </Link>
          <div className="w-full">
            <div className="flex flex-col justify-start items-start gap-2.5">
              {platformDevPoints.map((item, index) => (
                <div
                  key={index}
                  className="px-2.5 py-1.5 justify-start items-center gap-2 inline-flex"
                >
                  <img src={check} className="check" alt="" />{" "}
                  <div className="text-base font-normal font-['Lexend'] leading-relaxed text-[#5e7493]">
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="md:basis-1/2">
          <img src={SnowflakeSavings} alt="snowflake-savings" />
        </div>
      </div>
    </div>
  </div>
);
